import React, { useState } from 'react';

import { Box, Button, FormControl, FormErrorMessage, Input, InputGroup, InputRightElement, useToast } from '@chakra-ui/react';

import CardHeader from './CardHeader';
import Card from './Card';
import useUnauthorized from '../hooks/useUnauthorized';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import API from '../services/api';
import Property from './Property';

import { useHistory } from 'react-router-dom';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

function ChangePassword() {

    const formModel = { currentPassword: "", password: "", confirmPassword: "" }
    const formSchema = Yup.object().shape({
        currentPassword: Yup.string()
            .required('Current password is required')
            .min(8, 'Password must be 8-15 characters'),
        password: Yup.string()
            .required('Password is required')
            .min(8, 'Password must be 8-15 characters')
            .max(15, 'Password must be 8-15 characters')
            .matches(/[a-z]/, "Please use at least one lowercase character")
            .matches(/[A-Z]/, "Please use at least one uppercase character")
            .matches(/[0-9]/, "Please use at least one number")
            .matches(/[!@#$%^&*]/, "Please use at least one special character"),
        confirmPassword: Yup.string()
            .required('Confirm password is required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
    });
    const toast = useToast();
    const history = useHistory();
    const handleUnauthorized = useUnauthorized();
    const [showPassword, setShowPassword] = useState(false);

    return (
        <Box as="section" py="12" px={{ md: '8' }}>
            <Card maxW="3xl" mx="auto">
                <CardHeader title="Change Password" />
                <Formik
                    initialValues={formModel}
                    validationSchema={formSchema}
                    onSubmit={async (values, actions) => {
                        const apiResponse = await API._changePassword({ ...values, handleUnauthorized, toast })
                        if (apiResponse && apiResponse.success) {
                            actions.resetForm();
                            handleUnauthorized();
                            history.push("/login");
                        }
                        actions.setSubmitting(false);
                    }}
                >
                    {(props) => (
                    <Form>
                        <Box>

                            <Field name="currentPassword">
                                {({ field, form }) => (
                                <Property label="Current password" value={
                                    <FormControl isInvalid={form.errors.currentPassword && form.touched.currentPassword}>
                                        <InputGroup>
                                            <Input type={showPassword ? 'text' : 'password'} id="currentPassword" {...field} />
                                            <InputRightElement p={4}>
                                                <span style={{cursor:'pointer'}} onClick={()=>setShowPassword(!showPassword)}>
                                                {showPassword ? <VisibilityIcon style={{fontSize:'18px'}} /> : <VisibilityOffIcon style={{fontSize:'18px'}}/>}
                                                </span>
                                            </InputRightElement>
                                        </InputGroup>
                                        <FormErrorMessage>{form.errors.currentPassword}</FormErrorMessage>
                                    </FormControl>
                                }/>
                                )}
                            </Field>
                            <Field name="password">
                                {({ field, form }) => (
                                    <Property label="Password" value={
                                        <FormControl isInvalid={form.errors.password && form.touched.password}>
                                            <InputGroup>
                                                <Input type={showPassword ? 'text' : 'password'} id="password" {...field} />
                                                <InputRightElement p={4}></InputRightElement>
                                            </InputGroup>
                                            <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                        </FormControl>
                                    }/>
                                )}
                            </Field>
                            <Field name="confirmPassword">
                                {({ field, form }) => (
                                <Property label="Confirm password" value={
                                    <FormControl isInvalid={form.errors.confirmPassword && form.touched.confirmPassword}>
                                        <InputGroup>
                                            <Input type={showPassword ? 'text' : 'password'} id="confirmPassword" {...field} />
                                            <InputRightElement p={4}></InputRightElement>
                                        </InputGroup>
                                        <FormErrorMessage>{form.errors.confirmPassword}</FormErrorMessage>
                                    </FormControl>
                                }/>
                                )}
                            </Field>

                            <Property label={
                                <Button type="submit" bg={'brand.50'} color={'white'} size={'md'} 
                                    isLoading={props.isSubmitting} _hover={{ bg: 'brand.100' }}
                                    title="Submit"> Submit
                                </Button>
                            } />
                        
                        </Box>

                    </Form>
                    )}
                </Formik>
            </Card>
        </Box>
    )
}

export default ChangePassword;
