import React, { useEffect, useState } from 'react';
import './Home.css';

import Stat from './Stat'
import { Text, Stack, useToast } from '@chakra-ui/react';
import API from '../services/api';
import useUnauthorized from '../hooks/useUnauthorized';
import { useStateValue } from '../contexts/StateProvider';
import { Link } from 'react-router-dom';
import { toTitleCase } from '../utils/common';

function Home() {

    const [stats, setStats] = useState({ 'free': 0, 'premium': 0 })
    const handleUnauthorized = useUnauthorized();
    const toast = useToast();
    const [{ user },] = useStateValue();

    useEffect(() => {
        API._getStats({ handleUnauthorized, toast })
            .then((apiResponse) => {
                if (apiResponse && apiResponse.success) {
                    setStats({ ...apiResponse.data });
                }
            }) 
    }, [])

    // console.log(user)

    return (
        <div className="home">

            <Text fontSize={'3xl'} ml={3} my={4}>
                Welcome back, {user ? toTitleCase(user.firstName) : "admin"}
            </Text>
            
            <Stack direction={{ base: 'column', sm: 'row' }} ml={3} my={3}>
                <Link to='/users?type=Free'>
                    <Stat num={stats.free} label={`Total Free Users`} />
                </Link>
                <Link to='/users?type=Premium'>
                    <Stat num={stats.premium} label={'Total Premium Users'} />
                </Link>
            </Stack>

        </div>
    )
}

export default Home;
