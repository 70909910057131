import React, { useState } from 'react'

import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Link,
  Button,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  Image,
  Text,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react';

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { actionTypes } from '../contexts/reducer';
import { useStateValue } from '../contexts/StateProvider';

import { Link as Linking } from 'react-router-dom';

import API from '../services/api';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

function Login() {

  const [state, dispatch] = useStateValue();
  const [showPassword, setShowPassword] = useState(false);
  const toast = useToast();
  const formModel = { email: "", password: "" }
  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required('Enter your email')
      .email('Your email is not valid'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password is too short - should be 8 chars minimum')
      // .matches(/^[a-z0-9]+$/i, 'Password can only contain alphanumeric values.')
  });

  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'}>

      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>

        <Stack align={'center'}>
            <Image 
              src="https://storage.googleapis.com/penn_assets/logo-200.png" 
              alt="Penn logo" w={180}
            />
        </Stack>

        <Box rounded={'lg'} boxShadow={'lg'} p={8}
          bg={useColorModeValue('white', 'gray.700')}>

          <Text fontSize={'2xl'} fontWeight={'bold'} pb={6} pr={12}>Sign in to your account</Text>
          <Formik
            initialValues={formModel}
            validationSchema={formSchema}
            onSubmit={async (values, actions) => {
              const apiResponse = await API._login({ ...values, toast})
              if (apiResponse && apiResponse.success) {
                localStorage.setItem('userdata', JSON.stringify({ token: apiResponse.data.token, user: apiResponse.data.user }));
                dispatch({
                  type: actionTypes.SET_TOKEN,
                  token: apiResponse.data.token
                })
                dispatch({
                  type: actionTypes.SET_USER,
                  user: apiResponse.data.user
                })
              }
              actions.setSubmitting(false);
            }}
          >
            {(props) => (
              <Form>

                <Stack spacing={4}>

                  <Field name="email">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.email && form.touched.email}>
                        <FormLabel>Email address</FormLabel>
                        <Input type="email" id="email" {...field} />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="password">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.password && form.touched.password}>
                        <FormLabel>Password</FormLabel>
                        <InputGroup>
                          <Input type={showPassword ? 'text' : 'password'} id="password" {...field} />
                          <InputRightElement p={4}>
                            <span style={{cursor:'pointer'}} onClick={()=>setShowPassword(!showPassword)}>
                              {showPassword ? <VisibilityIcon style={{fontSize:'18px'}} /> : <VisibilityOffIcon style={{fontSize:'18px'}}/>}
                            </span>
                          </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Button type="submit" bg={'brand.50'} color={'white'} title="Submit"
                    isLoading={props.isSubmitting} _hover={{ bg: 'brand.100' }}> Sign in
                  </Button>
                  <Flex justify={'center'}>
                    <Linking to="/forgot-password">
                      <Link color={'brand.50'} fontSize={'md'}>Forgot password?</Link>
                    </Linking>
                  </Flex>

                </Stack>

              </Form>
            )}

          </Formik>

        </Box>

      </Stack>

    </Flex>
  );
}

export default Login

