import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import Login from '../components/Login'
import ForgotPassword from '../components/ForgotPassword'

function OnboardingPage() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <Route path="*" render={()=> <Redirect to="/login" />} />
            </Switch>
        </BrowserRouter>
    )
}

export default OnboardingPage
