import React, { useRef } from 'react';

import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Button,
  } from "@chakra-ui/react";

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

function DrawerComp(props) {
    
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = useRef()

    return (
        <>
            <Button ref={btnRef} colorScheme="brand" onClick={onOpen} size="sm" title="View details">
                <ArrowForwardIosIcon style={{ fontSize: 14 }} />
            </Button>
            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                finalFocusRef={btnRef}
                size={'sm'}
            >
                <DrawerOverlay />
                
                <DrawerContent>
                <DrawerCloseButton />
                {/* <DrawerHeader>User details </DrawerHeader> */}
                <DrawerBody>
                    {props.details}
                </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )

}

export default DrawerComp;
