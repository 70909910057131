import { actionTypes } from '../contexts/reducer';
import { useStateValue } from '../contexts/StateProvider';

function useUnauthorized() {
    
    const [state, dispatch] = useStateValue();

    const handleUnauthorized = () => {
        localStorage.clear();
        dispatch({
            type: actionTypes.RESET_STATE,
            token: null,
            user: null
        })
    }

    return handleUnauthorized;
}

export default useUnauthorized
