import { Flex, Heading } from '@chakra-ui/layout'
import React from 'react'

function CardHeader({ title, action }) {
    return (
        <Flex align="center" justify="space-between" px="6" py="4" mb="4" borderBottomWidth="1px">
            <Heading as="h2" fontSize="xl">
                {title}
            </Heading>
            {action}
        </Flex>
    )
}

export default CardHeader
