import React, { useState } from 'react';

import { Box, Button, Flex, Input, useToast } from '@chakra-ui/react';

import CardHeader from './CardHeader';
import Property from './Property';
import Card from './Card';

import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

import { toTitleCase } from '../utils/common'

import { useStateValue } from '../contexts/StateProvider'

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Avatar, IconButton } from '@material-ui/core';
import { BASE_URL } from '../utils/constant';
import API from '../services/api';
import { actionTypes } from '../contexts/reducer';

function Profile() {

    const [{ user, token }, dispatch] = useStateValue();
    const {firstName, lastName, email, userImage} = user || {firstName:"", lastName:"", email:""};
    const [editMode, setEditMode] = useState(false);
    const toast = useToast();
    let fileInput; 
    const [file, setFile] = useState(null);

    const ProfileForm = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        file: null
    }

    const ProfileSchema = Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required')
    });

    const formik = useFormik({
        initialValues: ProfileForm,
        enableReinitialize: true,
        validationSchema: ProfileSchema,
        onSubmit: async (values, { resetForm }) => {
            let formData = new FormData();
            formData.append("firstName", values.firstName);
            formData.append("lastName", values.lastName);
            formData.append("profile", values.file);
            const apiResponse = await API._updateProfile({ formData, toast})
            if (apiResponse && apiResponse.success) {
                localStorage.setItem('userdata', JSON.stringify({ token, user: apiResponse.data }));
                dispatch({
                    type: actionTypes.SET_USER,
                    user: apiResponse.data
                })
                resetForm();
                setEditMode(false);
            }
        },
      });

    return (
        <Box as="section" py="12" px={{ md: '8' }}>
            <Card maxW="3xl" mx="auto">
                <form onSubmit={formik.handleSubmit}>
                    <CardHeader
                        title="Account Info"
                        action={
                            editMode
                            ?
                            <Button type="submit" bg={'brand.50'} color={'white'}
                                _hover={{ bg: 'brand.100' }} title="Submit">
                                Save
                            </Button>
                            :
                            <Button type="button" variant="outline" title="Edit"
                                onClick={(e)=>{e.preventDefault();setEditMode(true);}}>
                                Edit
                            </Button>
                        }
                    />
                    <Box>
                        <Property 
                            label="Profile Pic"
                            value={
                                <Flex align="flex-end">
                                    <input type="file" id="file" ref={ref => fileInput = ref} style={{ display: 'none' }} 
                                        onChange={e => {
                                            if (e.currentTarget.files[0]) {
                                                formik.setFieldValue("file", e.currentTarget.files[0]) 
                                                setFile(URL.createObjectURL(e.currentTarget.files[0]))
                                            }
                                        }} 
                                    />
                                    <Avatar
                                        src={editMode ? (file ? file : `${BASE_URL}/uploads/images/${userImage}`) : `${BASE_URL}/uploads/images/${userImage}`} 
                                        style={{ height: '70px', width: '70px' }} variant="rounded"
                                    />
                                    {editMode && (
                                        <div 
                                            style={{ cursor: 'pointer', padding: '4px 6px', marginLeft: '-10px', zIndex: 1, borderRadius: '50%', backgroundColor: '#30639C' }}
                                            onClick={() => fileInput.click()}
                                        >
                                            <PhotoCameraIcon style={{ fontSize: '16px', color: 'white' }} />
                                        </div>
                                    )}
                                </Flex>
                            }
                         />
                        <Property 
                            label="First Name" 
                            value={
                                !editMode 
                                ? 
                                `${toTitleCase(firstName)}`
                                :
                                <>
                                    <Input type='text' id="firstName" onChange={formik.handleChange} value={formik.values.firstName} />
                                    {formik.errors.firstName || formik.touched.firstName ? <div className="formError">{formik.errors.firstName}</div> : null}
                                </>
                            }
                         />
                        <Property 
                            label="Last Name" 
                            value={
                                !editMode 
                                ?
                                `${toTitleCase(lastName)}`
                                :
                                <>
                                    <Input type='text' id="lastName" onChange={formik.handleChange} value={formik.values.lastName} />
                                    {formik.errors.lastName || formik.touched.lastName ? <div className="formError">{formik.errors.lastName}</div> : null}
                                </>
                            }
                         />
                        <Property 
                            label="Email" 
                            value={
                                !editMode
                                ? 
                                email
                                :
                                <Input type='text' id="email" onChange={formik.handleChange} value={formik.values.email} isDisabled={true}/>
                            }
                        />
                    </Box>
                </form>
            </Card>
        </Box>
    )
}

export default Profile;
