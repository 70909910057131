import React, { useState, useRef, initialValue, useEffect } from "react";
import "./Users.css";

import moment from "moment";

import { muiTableStyle } from "../utils/theme";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Chip,
  TablePagination,
  TableFooter
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import ReplayIcon from "@material-ui/icons/Replay";

import Export from "./Export";
import API from "../services/api";
import useUnauthorized from "../hooks/useUnauthorized";
import { useToast } from "@chakra-ui/toast";

import DrawerComp from "./DrawerComp";
import Details from "./Details";
import { Button } from "@chakra-ui/react";

import { BASE_URL } from "../utils/constant";
import { globalTheme } from "../utils/theme";

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";

import { useLocation } from "react-router-dom";
import { Select } from "@chakra-ui/react";

function Users() {

  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const tableClasses = muiTableStyle();
  const handleUnauthorized = useUnauthorized();
  const toast = useToast();

  const initialValue = {
    from: null,
    to: null,
  };

  const tableHeaderCell = [
    { id: "userDetails", label: "Contacts", disableSorting: true },
    { id: "createdAt", label: "Created", disableSorting: false },
    { id: "type", label: "Plan", disableSorting: true },
    { id: "planDetails", label: "Plan Details", disableSorting: true },
    { id: "invites", label: "Invitees", disableSorting: false },
    { id: "lastActive", label: "Last Active", disableSorting: false },
    { id: "action", label: "Action", disableSorting: true },
  ];

  const [selectedDayRange, setSelectedDayRange] = useState(initialValue);
  const [dateRange, setDateRange] = useState(initialValue);

// PAGINATION CODES
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }; 

  useEffect(() => {
    fetchData('paginationChange');
  }, [page, rowsPerPage]);

// SEARCH CODES    
  const handleSearch = (event) => {
    fetchData();
    event.preventDefault();
  };

  const handleCancel = (event) => {
    setSearch("");
    fetchData("searchBlank");
    event.preventDefault();
  };

// DATE CODES  
  const handleDateChange = async (event) => {
    setSelectedDayRange(event);
    if (event.to) {
      setDateRange({
        from: moment({ ...event.from, month: event.from.month - 1 }).toDate(),
        to: moment({ ...event.to, month: event.to.month - 1 })
          .endOf("day")
          .toDate(),
      });
    }
  };

  const fetchOnDateReset = () => {
    setSelectedDayRange(initialValue);
    setDateRange(initialValue);
    dateRange.from = dateRange.to = null;
    fetchData();
  }
  
  useEffect(() => {
    if (dateRange.from && dateRange.to) {
      fetchData();
    }
  }, [dateRange.to]);

// USER FILTER CODES
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const user_type = useRef(initialValue);
  const query = useQuery();
  
  useEffect(() => {
    query.get("type") ? user_type.current = query.get("type") : user_type.current = "All";
  }, []);

  const filterUser = (e) => {
    user_type.current = e.target.value;
    fetchData();
  };

// FETCH API CODES
  const fetchData = (param) => {
    let data, type;
    let searchData = "";
    if (param === "searchBlank") searchData = "";
    else searchData = search;
    typeof(user_type.current) === 'string' ? type = user_type.current : type = query.get("type");
    if (type === "Free" || type === "Premium") {
      data = {
        page: page + 1,
        limit: rowsPerPage,
        search: searchData,
        type: type,
        from: dateRange.from,
        to: dateRange.to,
        handleUnauthorized,
        toast
      }
    } else {
      data = {
        page: page + 1,
        limit: rowsPerPage,
        search: searchData,
        from: dateRange.from,
        to: dateRange.to,
        handleUnauthorized,
        toast
      }
    }
    API._getUsers(data).then((apiResponse) => {
      if (apiResponse && apiResponse.success) {
        setUsers(apiResponse.data);
        setTotal(apiResponse.metadata[0].total);
      }
    });
    if (param !== 'paginationChange') {
      setPage(0);
    }
  }

// DATE PICKER CODES
  const customDateInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      placeholder="Select created date range"
      value={
        selectedDayRange.from && selectedDayRange.to
          ? `From ${selectedDayRange.from.month}/${selectedDayRange.from.day}/${selectedDayRange.from.year} To ${selectedDayRange.to.month}/${selectedDayRange.to.day}/${selectedDayRange.to.year}`
          : ""
      }
      className="date__input"
    />
  );

  return (
    <div className="users">
      <div className="users__filter">
        <form>
          <div>
            <input
              placeholder={`Search users`}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {search && (
              <CloseIcon
                style={{ fontSize: "20px", cursor: "pointer" }}
                onClick={handleCancel}
              />
            )}
          </div>
          <Button
            colorScheme="brand"
            size="sm"
            px={"9px"}
            py={"18px"}
            type="submit"
            onClick={handleSearch}
            title="Search"
          >
            <SearchIcon style={{ fontSize: "20px" }} />
          </Button>
        </form>

        <div className="select_filter">
        <Select value={user_type.current} onChange={filterUser} bg="primary">
          <option value="All">All</option>
          <option value="Free">Free</option>
          <option value="Premium">Premium</option>
        </Select>
        </div>

        <div>
          <DatePicker
            value={selectedDayRange}
            onChange={handleDateChange}
            renderInput={customDateInput}
            colorPrimary={globalTheme.color.primary}
            colorPrimaryLight={globalTheme.color.hover}
            calendarClassName="custom_calender"
          />
          <Button
            colorScheme="brand"
            size="sm"
            px={"9px"}
            py={"18px"}
            ml={"5px"}
            title="Reset date"
            onClick={() => {
              fetchOnDateReset();
            }}
          >
            <ReplayIcon style={{ fontSize: "20px" }} />
          </Button>
        </div>

        <Export search={search} from={dateRange.from} to={dateRange.to} type={user_type.current} />
      </div>

      <TableContainer component={Paper} className={tableClasses.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaderCell.map((cell) => (
                <TableCell
                  key={cell.id}
                  className={tableClasses.tableHeaderCell}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {/* {cell.disableSorting ? cell.label : (
                                            <TableSortLabel>
                                                {cell.label}
                                            </TableSortLabel>
                                        )} */}
                  {cell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user._id}>
                <TableCell className={tableClasses.tableRowCell}>
                  <div style={{ display: "flex" }}>
                    <Avatar
                      src={
                        user?.userImage
                          ? `${BASE_URL}/uploads/images/${user?.userImage}`
                          : "."
                      }
                      alt={user?.email}
                      className={tableClasses.avatarSmall}
                    />
                    <div className="users__userDetails">
                      <p>
                        {user.firstName} {user.lastName}
                      </p>
                      <p>{user.email}</p>
                    </div>
                  </div>
                </TableCell>
                <TableCell className={tableClasses.tableRowCell} style={{ whiteSpace: "nowrap" }}>
                  {moment(user.createdAt).format("lll")}
                </TableCell>
                <TableCell className={tableClasses.tableRowCell}>
                  <Chip
                    size="small"
                    variant="outlined"
                    style={{ fontSize: 12 }}
                    label={user.subscriptionPlan?.planName}
                    color={
                      user.subscriptionPlan?.planName === "Premium"
                        ? "primary"
                        : "grey"
                    }
                  />
                </TableCell>
                <TableCell
                  className={tableClasses.tableRowCell}
                  style={{ textAlign: "center" }}
                >
                  {user.subscriptionPlan?.planName === "Premium" ? (
                    <div className="users__planDetails">
                      <p>{user.subscriptionPlan?.planFrequency}</p>
                      <p style={{ whiteSpace: "nowrap" }}>
                        since{" "}
                        {moment(user.subscriptionPlan?.subscribedAt).format(
                          "MMM Do, YY"
                        )}
                      </p>
                    </div>
                  ) : (
                    "N/A"
                  )}
                </TableCell>
                <TableCell className={tableClasses.tableRowCell}>
                  <Chip
                    size="small"
                    variant="outlined"
                    style={{ fontSize: 12 }}
                    label={user.invites.length > 0 ? "Yes" : "No"}
                    color={
                      user.invites.length > 0
                        ? "primary"
                        : "grey"
                    }
                  />
                </TableCell>
                <TableCell className={tableClasses.tableRowCell} style={{ whiteSpace: "nowrap" }}>
                  {user.last_active ? moment(user.last_active).format('lll') : 'N/A'}
                </TableCell>
                <TableCell className={tableClasses.tableRowCell}>
                  <DrawerComp
                    details={<Details {...user} {...user.subscriptionPlan} />}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TablePagination
              component="div"
              rowsPerPageOptions={[10, 25, 50, 100]}
              count={total}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage="Rows per page"
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Users;
