import React from 'react'

import axios from 'axios';

import { Box, useColorModeValue } from '@chakra-ui/react';

import { actionTypes } from '../contexts/reducer';
import { useStateValue } from '../contexts/StateProvider';

import DashboardPage from '../pages/DashboardPage';
import OnboardingPage from '../pages/OnboardingPage';

function App() {

  const [state, dispatch] = useStateValue();

  const dataString = localStorage.getItem('userdata');
  const localStore = JSON.parse(dataString);

  if (localStore && !state.token) {
    // console.log('setting the state');
    dispatch({
      type: actionTypes.SET_TOKEN,
      token: localStore?.token
    })
    dispatch({
      type: actionTypes.SET_USER,
      user: localStore?.user
    })
  }

  axios.defaults.headers.common['Authorization'] = `Bearer ${localStore?.token}`;

  return (
    <Box bg={useColorModeValue('gray.100', 'gray.800')} minH={'100vh'}>
      {
        !localStore?.token ? <OnboardingPage /> : <DashboardPage />
      }
    </Box>
  )
}

export default App
