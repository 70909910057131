import React from 'react';

import { Box, Text } from '@chakra-ui/react';

function Stat({ num, label }) {
    return (
        <Box align={'center'} px={'24'} py={8} rounded={'lg'}
            shadow="base" bg={'white'}>
            <Text fontSize={'4xl'} fontWeight={'bold'}>{num}</Text>
            <Text w={36} fontSize={'md'} textColor={'gray.500'}>{label}</Text>
        </Box>
    )
}

export default Stat;
