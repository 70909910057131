import { Box, Flex, Image } from '@chakra-ui/react'
import React from 'react'

function Property({ label, value, children }) {
    return (
        <Flex
            /* as="dl" */ px="6" py="3" fontSize={'md'}
            direction={{ base: 'column', sm: 'row' }}
            // _even={{ bg: useColorModeValue('gray.50', 'gray.600') }}
            // {...children}
            >
            <Box /* as="dt" */ minW="180px">
                {label}
            </Box>
            <Box /* as="dd" flex="1" */ fontWeight="semibold">
                {value}
            </Box>
            {children}
        </Flex>
    )
}

export default Property
