import React from 'react'
import {
    Box,
    Flex,
    Image,
    Avatar,
    IconButton,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    useDisclosure,
    useToast
} from '@chakra-ui/react';

import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { Link, useHistory } from 'react-router-dom';

import Navbar from './Navbar';
import API from '../services/api';
import useUnauthorized from '../hooks/useUnauthorized';
import { useStateValue } from '../contexts/StateProvider';

import { BASE_URL } from '../utils/constant';


function Header() {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const handleUnauthorized = useUnauthorized();
    const toast = useToast();
    const history = useHistory();
    const [{ user },] = useStateValue();

    const logout = async () => {
        const apiResponse = await API._logout({ handleUnauthorized, toast })
        if (apiResponse && apiResponse.success) {
            handleUnauthorized();
            history.push("/login");
        }
    }

    return (

        <Box bg={'white'} px={4} boxShadow="base" position="relative">
            <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                <IconButton
                    size={'md'}
                    icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                    aria-label={'Open Menu'}
                    display={{ md: !isOpen ? 'none' : 'inherit' }}
                    onClick={isOpen ? onClose : onOpen}
                />
                <Link to='/'>
                    <Image 
                        src="https://storage.googleapis.com/penn_assets/logo-200.png" 
                        alt="Penn logo" w={150}
                    />
                </Link>
                <Navbar desktop={true} />
                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton as={Button} rounded={'full'} variant={'link'} cursor={'pointer'}>
                            <Avatar
                                size={'md'}
                                src={
                                    `${BASE_URL}/uploads/images/${user?.userImage}`
                                }
                            />
                        </MenuButton>
                        <MenuList fontSize={'md'}>
                            <Link to='/profile'>
                                <MenuItem>
                                    Profile
                                </MenuItem>
                            </Link>
                            <Link to='/change-password'>
                                <MenuItem>
                                    Change Password
                                </MenuItem>
                            </Link>
                            <MenuDivider />
                            <MenuItem color={'red'} onClick={logout}>Log out</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </Flex>

            {isOpen ? (
                <Box pb={4}>
                    <Navbar desktop={false} />
                </Box>
            ) : null}

        </Box>
    )
}

export default Header