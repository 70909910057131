import React from 'react';
import ReactDOM from 'react-dom';

import { ChakraProvider } from '@chakra-ui/react';
import { MuiThemeProvider } from '@material-ui/core';

import App from './components/App';
import { chakraTheme, muiTheme } from './utils/theme';

import reducer, { initialState } from './contexts/reducer';
import { StateProvider } from './contexts/StateProvider';


ReactDOM.render(
  <React.StrictMode>
    <StateProvider initialState={initialState} reducer={reducer} >
      <ChakraProvider theme={chakraTheme}>
        <MuiThemeProvider theme={muiTheme}>
          <App />
        </MuiThemeProvider>
      </ChakraProvider>
    </StateProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
