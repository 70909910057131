import React from 'react'

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Header from '../components/Header';
import Home from '../components/Home';
import Users from '../components/Users';
import Profile from '../components/Profile';
import ChangePassword from '../components/ChangePassword';
import SendNotification from '../components/Notification/sendNotification';
import ViewNotification from '../components/Notification/viewNotification';
import AllNotifications from '../components/Notification/allNotifications';

function DashboardPage() {
    return (
        <BrowserRouter>
            <Header />
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/users" component={Users} />
                <Route path="/send-notification" component={SendNotification} />
                <Route path="/view-notification" component={ViewNotification} />
                <Route path="/custom-notifications" component={AllNotifications} />
                <Route path="/profile" component={Profile} />
                <Route path="/change-password" component={ChangePassword} />
                <Route path="*" render={()=> <Redirect to="/" />} />
            </Switch>
        </BrowserRouter>
    )
}

export default DashboardPage
