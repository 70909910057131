import { createMuiTheme, makeStyles } from "@material-ui/core";
import { extendTheme } from "@chakra-ui/react";

export const globalTheme = {
    color: {
        primary: '#6BB292',
        hover: '#e9f7f3'
    }
}

export const muiTheme = createMuiTheme({
    typography: {
      fontFamily: `'Poppins', 'Helvetica', 'Arial', sans-serif`,
    }
});

export const muiTableStyle = makeStyles((theme) => ({
    palette: {
        secondary: {
          main: '#E2E8F0'
        }
    },
    tableContainer: {
        fontFamily: "'Poppins', sans-serif",
        maxWidth: 1200,
        fontSize: 12
    },
    tableHeaderCell: {
        fontWeight: 'bold',
        fontSize: "13px"
    },
    tableRowCell: {
        padding: 10,
        fontSize: "13px"
    },
    tableFooter: {
        fontSize: "3px"
    },
    avatarSmall: {
        width: "35px",
        height: "35px",
        textTransform: "uppercase"
    }
}), {index: 1});

export const chakraTheme = extendTheme({
    fontSizes: {
        xs: "0.60rem",
        sm: "0.75rem",
        md: "0.875rem",
        lg: "1rem",
        xl: "1.125rem",
        "2xl": "1.25rem",
        "3xl": "1.5rem",
        "4xl": "1.75rem",
        "5xl": "2.25rem",
        "6xl": "3rem",
        "7xl": "3.75rem",
        "8xl": "4.5rem",
        "9xl": "6rem",
    },
    fontFamily: 'Poppins',
    colors: {
        brand: {
            50: "#6bb292",
            100: "#529e7c",
            500: "#6bb292", // colorScheme default
            600: "#529e7c"  // colorScheme default - hover
        }
    }
})