import axios from 'axios';
import { BASE_URL } from '../utils/constant'

const _login = async (props) => {
    try {
        const response = await axios.post(`${BASE_URL}/auth/login?role=admin`, {
            email: props.email,
            password : props.password
        })
        return handleResponse(response);
    } catch (err) {
        const error = handleError(err);
        console.log("⛔ Error ", error);
        if (error !== "Unauthorized") props.toast({ status: "error", description: error.error })
        return error
    }
}

const _forgotPassword = async (props) => {
    try {
        const responseData = await axios.post(`${BASE_URL}/auth/users/forgotPassword?role=admin`, {
            email: props.email
        })
        const response = handleResponse(responseData);
        props.toast({ status: "info", description: response.message })
        return response
    } catch (err) {
        const error = handleError(err);
        console.log("⛔ Error ", error);
        if (error !== "Unauthorized") props.toast({ status: "error", description: error.error })
        return error
    }
}

const _changePassword = async (props) => {
    try {
        const responseData = await axios.post(`${BASE_URL}/api/users/changePassword`, {
            oldPassword: props.currentPassword,
            newPassword: props.password,
            confirmNewPassword: props.confirmPassword,
        })
        const response = handleResponse(responseData);
        props.toast({ status: "success", description: response.message })
        return response
    } catch (err) {
        const error = handleError(err, props);
        console.log("⛔ Error ", error);
        if (error !== "Unauthorized") props.toast({ status: "error", description: error.error })
        return error
    }
}

const _logout = async (props) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/users/logout`)
        return handleResponse(response);
    } catch (err) {
        const error = handleError(err, props);
        console.log("⛔ Error ", error);
        if (error !== "Unauthorized") props.toast({ status: "error", description: error.error })
        return error
    }
}

const _updateProfile = async (props) => {
    try {
        const responseData = await axios.post(`${BASE_URL}/api/users/updateInfo`, 
            props.formData
        )
        const response = handleResponse(responseData);
        props.toast({ status: "success", description: response.message })
        return response;
    } catch (err) {
        const error = handleError(err);
        console.log("⛔ Error ", error);
        if (error !== "Unauthorized") props.toast({ status: "error", description: error.error })
        return error
    }
}

const _getUsers = async (props) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/users/all`, {
            page: props.page,
            limit : props.limit,
            search : props.search,
            from : props.from,
            to : props.to,
            type: props.type
        })
        return handleResponse(response);
    } catch (err) {
        const error = handleError(err, props);
        console.log("⛔ Error ", error);
        if (error !== "Unauthorized") props.toast({ status: "error", description: error.error })
        return error
    }
}

const _getStats = async (props) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/users/stats`)
        return handleResponse(response);
    } catch (err) {
        const error = handleError(err, props);
        console.log("⛔ Error ", error);
        if (error !== "Unauthorized") props.toast({ status: "error", description: error.error })
        return error
    }
}

// Send custom notifications
const _sendCustomNotifications = async (props) => {
    try {
        const responseData = await axios.post(`${BASE_URL}/api/users/sendCustomNotification`, {
            userType: props.values.userType,
            title : props.values.title,
            body : props.values.plan_description,
            description : props.values.description,
            link: props.values.hyperlink
        })
        const response = handleResponse(responseData);
        props.toast({ status: "success", description: response.message })
        return response;
    } catch (err) {
        const error = handleError(err, props);
        console.log("⛔ Error ", error);
        if (error !== "Unauthorized") props.toast({ status: "error", description: error.error })
        return error
    }
}
const _getCustomNotifications = async (props) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/users/allCustomNotifications`, {
            page: props.page,
            limit : props.limit
        })
        return handleResponse(response);
    } catch (err) {
        const error = handleError(err, props);
        console.log("⛔ Error ", error);
        if (error !== "Unauthorized") props.toast({ status: "error", description: error.error })
        return error
    }
}
const _getCustomNotificationById = async (props) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/users/customNotificationById`, {
            id: props.id
        })
        return handleResponse(response);
    } catch (err) {
        const error = handleError(err, props);
        console.log("⛔ Error ", error);
        if (error !== "Unauthorized") props.toast({ status: "error", description: error.error })
        return error
    }
}

const _setPremium = async (props) => {
    try {
        const responseData = await axios.post(`${BASE_URL}/api/users/setPremium`, props)
        const response = handleResponse(responseData);
        props.toast({ status: "success", description: response.message })
        return response;
    } catch (err) {
        const error = handleError(err, props);
        console.log("⛔ Error ", error);
        if (error.status === 400 || error.status === 500) props.toast({ status: "error", description: error.message });
        else if (error !== "Unauthorized") props.toast({ status: "error", description: error.error })
        return error
    }
}

const handleResponse = (res) => {
    if (res.data) {
      return res.data;
    }
    return res;
  }
  
const handleError = (err, props) => {
    if (err.response) {
        // client received an error response (5xx, 4xx)
        if (err.response.data === "Unauthorized") {
            props.handleUnauthorized()
        } 
        return err.response.data
    } else if (err.request) {
        console.log("⛔ 2");
        // https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/
        // client never received a response, or request never left
        return { error: "Network error" }
    }
    return err
  }

const API = {
    _login,
    _getUsers,
    _getStats,
    _updateProfile,
    _forgotPassword,
    _changePassword,
    _logout,
    _sendCustomNotifications,
    _getCustomNotifications,
    _getCustomNotificationById,
    _setPremium
}

export default API;