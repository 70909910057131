import { Box, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

function Card(children) {
    return (
        <Box
            bg={useColorModeValue('white', 'gray.700')}
            rounded={{ md: 'lg' }}
            shadow="base"
            overflow="hidden"
            pb={4}
            {...children}
        />
    )
}

export default Card
