import React from 'react';

function Element({ label, value }) {
    return (
        <div>
            <p style={{ color: 'gray' }}>{label}</p>
            <p style={{ fontWeight: 500 }}>{value}</p>
        </div>
    )
}

export default Element;
