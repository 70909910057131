import React, { useRef, useState } from 'react'
import './Export.css'

import moment from 'moment';
import { CSVLink } from 'react-csv';

import GetAppIcon from '@material-ui/icons/GetApp';
import { useToast } from '@chakra-ui/toast';

import API from '../services/api';
import useUnauthorized from '../hooks/useUnauthorized';

function Export({ search = "", from = "", to = "", type }) {

    const [csvData, setCsvData] = useState([]);
    const csvLinkRef= useRef(null);
    const toast = useToast();
    const handleUnauthorized = useUnauthorized();

    const headers = [
        { label: "First Name", key: "firstName" },
        { label: "Last Name", key: "lastName" },
        { label: "Email", key: "email" },
        { label: "Created", key: "createdAt" },
        { label: "Plan", key: "subscriptionPlan.planName" },
        { label: "Last Active", key: "last_active" },
        { label: "Invitees", key: "invitees" },
        { label: "Subscription Frequency", key: "subscriptionPlan.planFrequency" },
        { label: "Subscription Device", key: "subscriptionPlan.subscriptionDevice" },
        { label: "Subscription Date", key: "subscriptionPlan.subscribedAt" },
        // { label: "Monthly Minutes", key: "monthlyMinutes" },
    ]
    
    const handleCSVData = async (event) => {
        const apiResponse = await API._getUsers({ page: 1, limit: 1000, search, from, to, type })
        if (apiResponse && apiResponse.success) {
            apiResponse.data.map(d=> {
                d.createdAt = moment(d.createdAt).format("MMMM Do YYYY, h:mm:ss a")
                d.last_active = d.last_active 
                    ? moment(d.last_active).format("MMMM Do YYYY, h:mm:ss a") 
                    : ""
                d.subscriptionPlan.subscribedAt = d.subscriptionPlan.subscribedAt 
                    ? moment(d.subscriptionPlan.subscribedAt).format("MMMM Do YYYY, h:mm:ss a") 
                    : ""
                // d.monthlyMinutes = parseFloat(d.monthlyMinutes).toFixed(2)
                d.invitees = d.invites.length > 0 
                    ? d.invitees = d.invites.map(invite => invite.email)
                    : "No"
                return d;
            })
            setCsvData(apiResponse.data)
            csvLinkRef.current.link.click();
        } else {
            if (apiResponse === "Unauthorized") handleUnauthorized();
            toast({ status: "error", description: apiResponse.error })
        }
    }

    return (
        <div className="export">
            <button onClick={handleCSVData} title="Export">
                <GetAppIcon style={{fontSize: '18px'}} /> Export
            </button>
            <CSVLink
                headers={headers}
                data={csvData}
                filename="penn_users.csv"
                ref={csvLinkRef}
                target="_blank" 
            />
        </div>
    )
}

export default Export
