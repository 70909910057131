import React from 'react'

import { NavLink } from 'react-router-dom';

import { HStack, Flex, Stack, useColorModeValue, } from '@chakra-ui/react';

import HomeIcon from '@material-ui/icons/Home';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import NotificationsIcon from '@material-ui/icons/Notifications';

import './Navbar.css';

function Navbar({ desktop }) {

    const Nav = ({ children }) => (
        <Flex
            px={3} py={2} rounded={'md'} fontSize={"md"}
            _hover={{
                textDecoration: 'none',
                bg: useColorModeValue('gray.100', 'gray.700'),
            }}>
            {children}
        </Flex>
    );

    return (
        <div>
            {
                desktop 
                ? 
                <HStack as={'nav'} align={'center'} mr={100}
                    display={{ base: 'none', md: 'flex' }}>
                    <NavLink exact to="/" activeClassName="active_link">
                        <Nav>
                            <HomeIcon style={{ fontSize:"20", marginRight:"4" }}/> Home
                        </Nav>
                    </NavLink>
                    <NavLink to="/users" activeClassName="active_link">
                        <Nav>
                            <PeopleAltIcon style={{ fontSize:"20", marginRight:"4" }}/> Users
                        </Nav>
                    </NavLink>
                    <NavLink to="/custom-notifications" activeClassName="active_link">
                        <Nav>
                            <NotificationsIcon style={{ fontSize:"20", marginRight:"4" }}/> Notifications
                        </Nav>
                    </NavLink>
                </HStack>
                : 
                <Stack as={'nav'}>
                    <NavLink exact to="/">
                        <Nav>Home</Nav>
                    </NavLink>
                    <NavLink to="/users">
                        <Nav>Users</Nav>
                    </NavLink>
                    <NavLink to="/custom-notifications">
                        <Nav>Notifications</Nav>
                    </NavLink>
                </Stack>
            }
        </div>
    )
}

export default Navbar
