import React from 'react'

import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Button,
    Text,
    useColorModeValue,
    FormErrorMessage,
    useToast,
    Image,
    Link
  } from '@chakra-ui/react';
  
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import API from '../services/api';
import { useHistory } from 'react-router-dom';
import { Link as Linking } from 'react-router-dom';

function ForgotPassword() {
  
  const toast = useToast();
  const history = useHistory();
  const formModel = { email: "" }
  const formSchema = Yup.object().shape({
    email: Yup.string()
    .required('Enter your email')
    .email('Your email is not valid')
  });

    return (
    <Flex minH={'100vh'} align={'center'} justify={'center'}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>

        <Stack align={'center'}>
            <Image 
              src="https://storage.googleapis.com/penn_assets/logo-200.png" 
              alt="Penn logo" w={180}
            />
          
        </Stack>

        <Box rounded={'lg'} boxShadow={'lg'} p={8}
          bg={useColorModeValue('white', 'gray.700')}>

          {/* <Text fontSize={'4xl'} fontWeight={'bold'} px={20}>Forgot password</Text> */}
          <Text fontSize={'2xl'} fontWeight={'bold'} pb={2} pr={12}>Forgot password</Text>
          <Text fontSize={'md'} color={'gray.600'} pb={4} >
            Enter your email and we'll send you a link to<br />
             reset password.
          </Text>

          <Formik
            initialValues={formModel}
            validationSchema={formSchema}
            onSubmit={async (values, actions) => {
              const apiResponse = await API._forgotPassword({ ...values, toast })
              if (apiResponse && apiResponse.success) {
                history.push("/login");
              }
              actions.setSubmitting(false);
            }}
          >
            {(props) => (
              <Form>
                <Stack spacing={4}>
                  <Field name="email">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.email && form.touched.email}>
                        {/* <FormLabel>Email address</FormLabel> */}
                        <Input type="email" id="email" {...field} />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Button type="submit" bg={'brand.50'} color={'white'} title="Submit"
                    isLoading={props.isSubmitting} _hover={{ bg: 'brand.100' }}> Submit
                  </Button>
                  <Flex justify={'center'}>
                    <Linking to="/login">
                      <Link color={'brand.50'} fontSize={'md'}>{`< back to sign in`}</Link>
                    </Linking>
                  </Flex>
                </Stack>
              </Form>
            )}
          </Formik>
        
        </Box>
      </Stack>
    </Flex>
  );
}

export default ForgotPassword
